import React from 'react';
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Moment from 'react-moment';
import { graphql } from 'gatsby';
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import colors from "styles/colors";
import Layout from "components/Layout";
import Newsletter from "components/newsletter";


const PostHeroContainer = styled("div")`
    max-height: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 3em;

    img {
        width: 100%;
    }
`

const PostHeroAnnotation = styled("div")`
    padding-top: 0.25em;

    h6 {
        text-align: right;
        color: ${colors.grey600};
        font-weight: 400;
        font-size: 0.85rem;
    }

    a {
        color: currentColor;
    }
`

const PostCategory = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
    font-weight: 600;
    color: ${colors.grey600};

    h5 {
        margin-top: 0;
        margin-bottom: 1em;
    }
`

const PostTitle = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    text-align: center;

    h1 {
        margin-top: 0;
    }
`

const PostBody = styled("div")`
    max-width: 100%;
    margin: 0 auto;

    .block-img {
        margin-top: 3.5em;
        margin-bottom: 0.5em;

        img {
            width: 100%;
        }
    }
    pre {
font-family: 'Inter var', sans-serif;
font-size: 24px;
font-style: italic;
line-height: 1.6;
text-align: center;
white-space: normal;
    }

    .Highlight1 { color: ${colors.blue600};    background-color: ${colors.blue200}; padding:2px;
    &:hover {
        cursor: pointer;
        transition: all 100ms ease-in-out;
        color: ${colors.blue500};
        background-color: inherit;
      }
  }
    .Highlight2 {color: ${colors.orange600};  background-color: ${colors.orange200}; padding:2px;
    &:hover {
        cursor: pointer;
        transition: all 100ms ease-in-out;
        color: ${colors.orange500};
        background-color: inherit;
      }}
    .Highlight3 { color: ${colors.purple600};  background-color: ${colors.purple200}; padding: 2px;&:hover {
        cursor: pointer;
        transition: all 100ms ease-in-out;
        color: ${colors.purple500};
        background-color: inherit;
      }}
    .Highlight4 { color: ${colors.green600};   background-color: ${colors.green200}; padding: 2px; &:hover {
        cursor: pointer;
        transition: all 100ms ease-in-out;
        color: ${colors.green500};
        background-color: inherit;
      }}
    .Highlight5 { color: ${colors.teal600};    background-color: ${colors.teal200}; padding: 2px; &:hover {
        cursor: pointer;
        transition: all 100ms ease-in-out;
        color: ${colors.teal500};
        background-color: inherit;
      }}
      a.link-class {
        background-color: ${colors.pink100} !important;
        text-decoration: none;
        padding: 2px;
        position: relative;
         color: ${colors.pink700};



      }
`

const PostMetas = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    justify-content: space-between;
    font-size: 0.85em;
    color: ${colors.grey600};
`

const PostAuthor = styled("div")`
    margin: 0;
`

const PostDate = styled("div")`
    margin: 0;
`

const Page = ({ page, meta }) => {
  var description = page.post_preview_description==null ? page.post_title[0].text : page.post_preview_description[0].text;
    return (
        <>
        <Helmet>
            <title>{page.post_title[0].text}</title>
            <meta name="description" content={description} />
            <meta name="og:title" content={page.post_title[0].text} />
            <meta name="og:description" content={description} />
            <meta name="og:type" content='website' />
            <meta name="twitter:card" content='summary' />
            <meta name="twitter:creator" content='@kobyof' />
            <meta name="twitter:title" content={page.post_title[0].text} />
            <meta name="twitter:description" content={description} />
        </Helmet>


            <Layout>
                <PostCategory>
                    {RichText.render(page.post_category)}
                </PostCategory>
                <PostTitle>
                    {RichText.render(page.post_title)}
                </PostTitle>
                <PostMetas>
                    <PostAuthor>
                        {page.post_author}
                    </PostAuthor>
                    <PostDate>
                        <Moment format="MMMM D, YYYY">{page.post_date}</Moment>
                    </PostDate>
                </PostMetas>
                    {page.post_hero_image && (
                    <PostHeroContainer>
                        <img src={page.post_hero_image.url} alt="bees" />
                        <PostHeroAnnotation>
                            {RichText.render(page.post_hero_annotation)}
                        </PostHeroAnnotation>
                    </PostHeroContainer>
                )}
                <PostBody>
                    {RichText.render(page.post_body)}
                </PostBody>
                <br />
                <Newsletter />
            </Layout>
        </>
    )
}

export default ({ data }) => {
    const pageContent = data.prismic.allPages.edges[0].node;
    const meta = data.site.siteMetadata;
    return (
        <Page page={pageContent} meta={meta}/>
    )
}

Page.propTypes = {
    page: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

export const query = graphql`
    query PageQuery($uid: String) {
        prismic {
            allPages(uid: $uid) {
                edges {
                    node {
                        post_title
                        post_hero_image
                        post_hero_annotation
                        post_date
                        post_category
                        post_body
                        post_author
                        post_preview_description
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`
